<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-toolbar
          color="green darken-3"
          dark
          dense
        >
          <span>New Category</span>
          <v-spacer></v-spacer>
          <v-icon>mdi-shape</v-icon>
        </v-toolbar>
        <v-alert
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in custCatPostErrors"
          :key="index"
        >
          <span class="text-uppercase ">{{ item.first() }}</span>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Category ID"
          v-model="id"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Name"
          v-model="name"
        ></v-text-field>
      </v-col>
      <v-col
        cols="6"
      >
        <v-btn
          color="primary"
          @click="storeCustCat"
          dark
          :loading="custCatPosting"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import custCatRepository from '@/repositories/customer-category.repository'
import { postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const storeDelay = searchDelay()

const inputVars = () => ({
  id: null,
  name: null,
})

export default {
  name: 'CreateCustomerCategory',
  data () {
    return {
      ...inputVars(),
      ...postVars('cust-cat'),
    }
  },
  methods: {
    storeCustCat () {
      if (this.custCatPosting) return
      this.custCatPosting = true
      this.custCatPostErrors = []
      storeDelay(() => {
        const data = this.getInputData()
        custCatRepository.store(data)
          .then(() => { })
          .catch(e => { this.custCatPostErrors = errorHandler(e) })
          .then(() => { this.custCatPosting = false })
      })
    },
    getInputData () {
      return {
        id: this.id,
        name: this.name,
      }
    },
  },
}
</script>
